<template>
  <div class="container">
    <h1 style="text-align:center;">טופס הגדלת שעות</h1>
    <div class="warpper">
      <div class="field">
        <p>סניף</p>
        <InputText
          v-model="values.branche"
          type="text"
          placeholder="סניף"
          style="width:100%"
          disabled
        />
      </div>
      <div class="field">
        <p>ת.ז/דרכון</p>
        <InputText
          v-model="values.idNumber"
          type="text"
          placeholder="תז/דרכון"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם מלא של העובד/ת</p>
        <InputText
          v-model="values.fullName"
          type="text"
          placeholder="שם מלא של העובד/ת"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>בחר מחלקה</p>
        <Dropdown
          :options="departments"
          v-model="values.department"
          placeholder="בחר מחלקה"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>מכסת שעות התקן החודשי שמופיעות לעובד כרגע</p>
        <InputNumber
          v-model="values.michsat_shaot"
          mode="decimal"
          :useGrouping="false"
        />
        <small style="color:red; font-weight:bold;">
          נא לבדוק בפתק של העבודה כמה שעות מקסימום יש לעובד כרגע. ראה דוגמה: לחץ
          <span
            style="text-decoration: underline; cursor:pointer; font-size:1.1rem;"
            @click="showExample = !showExample"
            >כאן</span
          >
        </small>
      </div>
      <div class="field">
        <p>מספר שעות להגדלה</p>
        <InputNumber
          v-model="values.increasing_hours_qnt"
          mode="decimal"
          :useGrouping="false"
        />
        <small
          v-if="values.increasing_hours_qnt > 0"
          style="color:red; font-weight:bold; font-size:1.1rem;"
        >
          שים לב! סה"כ תקן שעות חודשיות יהיו:
          {{
            Number(values.michsat_shaot) + Number(values.increasing_hours_qnt)
          }}</small
        >
      </div>
      <div class="field">
        <p>סיבה להגדלת שעות</p>
        <Textarea
          v-model="values.reason"
          rows="5"
          cols="30"
          placeholder="נא לרשום את הסיבה להגדלת השעות לעובד/ת"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם מנהל הסניף המבקש</p>
        <InputText
          v-model="values.ownerName"
          type="text"
          placeholder="שם מנהל הסניף המבקש"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>כתובת דוא"ל לקבלת עדכון</p>
        <InputText
          v-model="values.email"
          type="text"
          placeholder="שם מנהל הסניף המבקש"
          style="width:100%"
        />
      </div>
    </div>

    <Button
      label="שלח"
      class="p-button-help send-btn"
      @click="handleSubmit"
    />

    <!-- JUST FOR MAIL -->
    <div class="form-email" id="form-email" v-show="false">
      <table
        style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך הבקשה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.createdAt.toLocaleDateString("he") }},{{
              values.createdAt.toLocaleTimeString("he")
            }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
            שם הסניף השולח
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
            {{ values.branche }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            סוג הבקשה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.type }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם העובד/ת
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.fullName }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מס' ת"ז של העובד/ת
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.idNumber }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם פותח הבקשה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.ownerName }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            אימייל לעדכון סטטוס וקבלת עדכונים
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.email }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מחלקה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.department }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מכסת שעות נוכחית
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.michsat_shaot }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מספר שעות להגדלה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.increasing_hours_qnt }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            סיבה להגדלת השעות
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.reason }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            סטטוס
          </th>
          <td
            style="border:1px solid #dddddd; text-align:right; padding: 8px; color:green"
          >
            {{ values.status }}
          </td>
        </tr>
      </table>
    </div>

    <Dialog header="דוגמה למכסת שעות" v-model:visible="showExample">
      <div class="example">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/rosman2021-d33ae.appspot.com/o/Increasing-hours%2FWhatsApp%20Image%202021-07-11%20at%2014.25.12.jpeg?alt=media&token=2301cb0d-2ba2-4a38-acda-d6d52ec447cf"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import Dialog from "primevue/dialog";
import {projectFirestore,projectFunctions} from '../../../../../firebase/config'
import {increment} from '@/Methods/office_request_funcs'
import swal from "sweetalert";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import store from '../../../../../store';

export default {
  components: { InputText, Dropdown, Textarea, InputNumber, Dialog },
  props:['docId'],
  setup(props) {
    const show_comment = ref(false);
    const showExample = ref(false);

    const error = ref("");
    const values = ref({
      branche: "",
      idNumber: "",
      fullName: "",
      department: "",
      michsat_shaot: 0,
      increasing_hours_qnt: 0,
      reason: "",
      ownerName: "",
      email: "",
      charge: "לא הוגדר",
      type: "הגדלת שעות",
      status: "חדש",
      createdAt: new Date()
    });

    const validation = () => {
      for (let key in values.value) {
        if (key == "idNumber") {
          if (values.value[key].length <7) {
            error.value = "נא למלא מספר זהות או דרכון תקין";
            return false;
          }
        }
        if (key == "fullName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם מלא של העובד";
            return false;
          }
        }
        if (key == "department") {
          if (values.value[key].length == 0) {
            error.value = "נא לבחור מחלקה";
            return false;
          }
        }
        if (key == "michsat_shaot") {
          if (values.value[key] == 0) {
            error.value = "נא להזין מכסת שעות";
            return false;
          }
        }
        if (key == "increasing_hours_qnt") {
          if (values.value[key] == 0) {
            error.value = "נא להזין מספר שעות להגדלה";
            return false;
          }
        }
        if (key == "reason") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא סיבה להגדלת שעות שעות ";
            return false;
          }
        }
        if (key == "ownerName") {
          if (values.value[key].length < 1) {
            error.value = "נא למלא את שם פותח הבקשה";
            return false;
          }
        }
        if (key == "email") {
          if (
            values.value[key].length == 0 ||
            !values.value[key].includes("@")
          ) {
            error.value = "נא למלא כתובת מייל תקינה";
            return false;
          }
        }
      }
      return true;
    };

    const handleSubmit = async () => {
      if (!validation()) {
        swal("בעיה", `${error.value}`, "warning");
      } else {
        const doc = projectFirestore.collection('Applications').doc(props.docId).collection('Increasing_Hours').doc()
        await doc.set({ ...values.value, id: doc.id });
        await increment(props.docId)
        sendEmailToUser();
        sendEmailToCharge();
        clearData();
        swal("הצלחה", "הטופס נשלח בהצלחה", "success");
      }
    };

    const departments = ref([
      "מנהל/ת",
      "סגן/ית",
      "א.מעדנייה",
      "מעדנייה",
      "א.קצבייה",
      "קצבייה",
      "ירקות",
      "סדרן/ית",
      "קופאי/ית",
      "ק.ראשי/ית",
      "מחסנאי/ית",
      "ניקיון",
      "שומר/ת"
    ]);
    onMounted(() => {
      setTimeout(() => {
        if(store.getters.user.branche){
          values.value.branche = store.getters.user.branche;
        }else{
          values.value.branche = store.getters.user.display_name;
        }
        values.value.email = store.getters.user.email
      }, 1000);
    });
    const clearData = () => {
      const temp = {
        branche: values.value.branche,
        idNumber: "",
        fullName: "",
        department: "",
        michsat_shaot: 0,
        increasing_hours_qnt: 0,
        reason: "",
        ownerName: "",
        email: values.value.email,
        charge: "לא הוגדר",
        type: "הגדלת שעות",
        status: "חדש",
        createdAt: new Date()
      };
      values.value = temp;
    };

    function sendEmailToUser() {
      projectFunctions.httpsCallable('send_email')({
        html: `<div>
                <h3>
                    שלום ${values.value.ownerName},
                </h3>
                <h3>
                    בקשתך המשרדית לעובד: ${values.value.fullName}
                </h3>
                <h3>
                    עבור: ${values.value.type}
                </h3>
                <h3>
                    לסניף/אתר ${values.value.branche}
                </h3>
                <h3>
                    נשלחה לטיפול!
                </h3>
                <h3 style="color:red;">
                    נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
                </h3>
            </div>`,
        destinations:[`${values.value.email}`],
        from:"מערכת לבקשות משרדיות",
        title:"נפתחה בקשה משרדית חדשה"
      }) 
    }

    function sendEmailToCharge() {
      projectFunctions.httpsCallable('send_email')({
        html: document.getElementById("form-email").innerHTML,
        destinations:["rosman.mate@gmail.com","ravit@rosman.co.il"],
        from:"מערכת לבקשות משרדיות",
        title:"נפתחה בקשה משרדית חדשה"
      })
    }

    return { values, departments, show_comment, handleSubmit, showExample };
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warpper {
  width: 50%;
  height: calc(100% - 100px);
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.field {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  margin-top: 15px;
}

.field p {
  font-size: 1.1rem;
}
.send-btn {
  margin-top: 5px;
  width: 50%;
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
input[type="time"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="time"]:hover {
  border: 1.1px solid lightblue;
}
input[type="time"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}

@media screen and (max-width: 600px) {
  .container {
    justify-content: unset;
    overflow: hidden;
    background: var(--secondary);
    color: #fff;
  }
  .warpper {
    width: 100%;
    border: none;
    overflow-y: auto;
    flex-shrink: 0;
  }
  .send-btn {
    width: 100%;
  }
  .upload-btn {
    width: 100%;
  }
}
.example {
  width: 400px;
  height: 400px;
}
.example img {
  width: 100%;
  height: 100%;
}
</style>
